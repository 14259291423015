
export const UserMutationTypes = {
  SET_TOKEN: 'SET_TOKEN',
  SET_LOGIN_USER: 'SET_LOGIN_USER',
  SET_AVATAR: 'SET_AVATAR',
  SET_INTRODUCTION: 'SET_INTRODUCTION',
  SET_ROLES: 'SET_ROLES',
  SET_EMAIL: 'SET_EMAIL',
  SET_SITE_ID: 'SET_SITE_ID',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS'
}
